export default defineNuxtRouteMiddleware((to) => {
  const localePath = useLocalePath()

  const redirect = useCookie('redirect', { sameSite: 'strict' })

  const userState = useUserState()
  if (!userState.value.tokenPair) {
    redirect.value = to.fullPath
    console.log('no tokenPair or is expired, redirecting...')
    return navigateTo(localePath('/sign_in'))
  }

  redirect.value = null
})
